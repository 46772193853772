/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateGeneralCorrespondenceDto } from '../model/createGeneralCorrespondenceDto';
import { CreateGeneralCorrespondenceReplyDto } from '../model/createGeneralCorrespondenceReplyDto';
import { GeneralCorrespondenceDto } from '../model/generalCorrespondenceDto';
import { GeneralCorrespondenceDtoPaginatedList } from '../model/generalCorrespondenceDtoPaginatedList';
import { GeneralCorrespondenceReplyDto } from '../model/generalCorrespondenceReplyDto';
import { GeneralCorrespondenceReplyDtoPaginatedList } from '../model/generalCorrespondenceReplyDtoPaginatedList';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GeneralCorrespondenceService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addGeneralCorrespondences(body?: CreateGeneralCorrespondenceDto, observe?: 'body', reportProgress?: boolean): Observable<GeneralCorrespondenceDto>;
    public addGeneralCorrespondences(body?: CreateGeneralCorrespondenceDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralCorrespondenceDto>>;
    public addGeneralCorrespondences(body?: CreateGeneralCorrespondenceDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralCorrespondenceDto>>;
    public addGeneralCorrespondences(body?: CreateGeneralCorrespondenceDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GeneralCorrespondenceDto>('post',`${this.basePath}/api/GeneralCorrespondence`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addGeneralCorrespondencesReply(body?: CreateGeneralCorrespondenceReplyDto, observe?: 'body', reportProgress?: boolean): Observable<GeneralCorrespondenceReplyDto>;
    public addGeneralCorrespondencesReply(body?: CreateGeneralCorrespondenceReplyDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralCorrespondenceReplyDto>>;
    public addGeneralCorrespondencesReply(body?: CreateGeneralCorrespondenceReplyDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralCorrespondenceReplyDto>>;
    public addGeneralCorrespondencesReply(body?: CreateGeneralCorrespondenceReplyDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GeneralCorrespondenceReplyDto>('post',`${this.basePath}/api/GeneralCorrespondence/Replies`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public closeGeneralCorrespondence(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public closeGeneralCorrespondence(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public closeGeneralCorrespondence(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public closeGeneralCorrespondence(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling closeGeneralCorrespondence.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/GeneralCorrespondence/Close/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGeneralCorrespondenceById(id: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralCorrespondenceDto>;
    public getGeneralCorrespondenceById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralCorrespondenceDto>>;
    public getGeneralCorrespondenceById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralCorrespondenceDto>>;
    public getGeneralCorrespondenceById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getGeneralCorrespondenceById.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GeneralCorrespondenceDto>('get',`${this.basePath}/api/GeneralCorrespondence/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param filters 
     * @param sortBy 
     * @param searchBy 
     * @param sortByDescending 
     * @param pageSize 
     * @param pageIndex 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGeneralCorrespondences(filters?: Array<string>, sortBy?: Array<string>, searchBy?: string, sortByDescending?: boolean, pageSize?: number, pageIndex?: number, observe?: 'body', reportProgress?: boolean): Observable<GeneralCorrespondenceDtoPaginatedList>;
    public getGeneralCorrespondences(filters?: Array<string>, sortBy?: Array<string>, searchBy?: string, sortByDescending?: boolean, pageSize?: number, pageIndex?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralCorrespondenceDtoPaginatedList>>;
    public getGeneralCorrespondences(filters?: Array<string>, sortBy?: Array<string>, searchBy?: string, sortByDescending?: boolean, pageSize?: number, pageIndex?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralCorrespondenceDtoPaginatedList>>;
    public getGeneralCorrespondences(filters?: Array<string>, sortBy?: Array<string>, searchBy?: string, sortByDescending?: boolean, pageSize?: number, pageIndex?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {
            filters.forEach((element) => {
                queryParameters = queryParameters.append('Filters', <any>element);
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = queryParameters.append('SortBy', <any>element);
            })
        }
        if (searchBy !== undefined && searchBy !== null) {
            queryParameters = queryParameters.set('SearchBy', <any>searchBy);
        }
        if (sortByDescending !== undefined && sortByDescending !== null) {
            queryParameters = queryParameters.set('SortByDescending', <any>sortByDescending);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GeneralCorrespondenceDtoPaginatedList>('get',`${this.basePath}/api/GeneralCorrespondence`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param filters 
     * @param sortBy 
     * @param searchBy 
     * @param sortByDescending 
     * @param pageSize 
     * @param pageIndex 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGeneralCorrespondencesReplies(id: string, filters?: Array<string>, sortBy?: Array<string>, searchBy?: string, sortByDescending?: boolean, pageSize?: number, pageIndex?: number, observe?: 'body', reportProgress?: boolean): Observable<GeneralCorrespondenceReplyDtoPaginatedList>;
    public getGeneralCorrespondencesReplies(id: string, filters?: Array<string>, sortBy?: Array<string>, searchBy?: string, sortByDescending?: boolean, pageSize?: number, pageIndex?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralCorrespondenceReplyDtoPaginatedList>>;
    public getGeneralCorrespondencesReplies(id: string, filters?: Array<string>, sortBy?: Array<string>, searchBy?: string, sortByDescending?: boolean, pageSize?: number, pageIndex?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralCorrespondenceReplyDtoPaginatedList>>;
    public getGeneralCorrespondencesReplies(id: string, filters?: Array<string>, sortBy?: Array<string>, searchBy?: string, sortByDescending?: boolean, pageSize?: number, pageIndex?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getGeneralCorrespondencesReplies.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {
            filters.forEach((element) => {
                queryParameters = queryParameters.append('Filters', <any>element);
            })
        }
        if (sortBy) {
            sortBy.forEach((element) => {
                queryParameters = queryParameters.append('SortBy', <any>element);
            })
        }
        if (searchBy !== undefined && searchBy !== null) {
            queryParameters = queryParameters.set('SearchBy', <any>searchBy);
        }
        if (sortByDescending !== undefined && sortByDescending !== null) {
            queryParameters = queryParameters.set('SortByDescending', <any>sortByDescending);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GeneralCorrespondenceReplyDtoPaginatedList>('get',`${this.basePath}/api/GeneralCorrespondence/${encodeURIComponent(String(id))}/Replies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
