import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppConfigService } from './core/app-config/app-config.service';
import { AuthGuard } from './shared/services/authguard';
import { DatePipe } from '@angular/common';
import { LoaderInterceptor } from './shared/services/loader-interceptor.service';
import { LoaderService } from './shared/services/loader.service';
import { UsersModule } from './users/users.module';
import { ConfirmationDialogComponent } from './shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { LoginService } from './shared/services/login.service';
import { NotificationsModule } from './notifications/notifications.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { UnauthorizedComponent } from './security/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './security/page-not-found/page-not-found.component';
import { HttpErrorInterceptor } from '@shared/services/http-error.interceptor';
import { MonitoringService } from './core/monitoring/monitoring.service';
import { ApiModule, BASE_PATH, TranslationService } from '../../swagger';
import { BaseDataService } from '@shared/services/base-data.service';
import { LanguageAdministrationModule } from './language-administration/language-administration.module';
import { TranslationHttpLoaderService } from '@shared/services/translation-http-loader.service';
import { WorkshopAdministrationModule } from './workshop-administration/workshop-administration.module';
import { WorkflowModule } from './workflow/workflow.module';
import { GeneralCorrespondenceModule } from './general-correspondence/general-correspondence.module';

function initializeKeycloak(keycloak: KeycloakService, appConfig: AppConfigService) {
  return () =>
    keycloak.init({
      config: {
        realm: appConfig.getConfig().keycloak.realm,
        url: appConfig.getConfig().keycloak.baseUrl,
        clientId: appConfig.getConfig().keycloak.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
    });
}

export function getApiBaseUrl(appConfig: AppConfigService) {
  return appConfig.getConfig().api.baseUrl;
}

@NgModule({
  declarations: [AppComponent, ConfirmationDialogComponent, UnauthorizedComponent, PageNotFoundComponent],
  imports: [
    ApiModule,
    MaterialModule,
    UsersModule,
    GeneralCorrespondenceModule,
    CoreModule,
    NotificationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule,
    LanguageAdministrationModule,
    WorkshopAdministrationModule,
    WorkflowModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationHttpLoaderService,
        deps: [TranslationService, LoginService],
      },
    }),
    BrowserAnimationsModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      name: 'eRCMS Devtools',
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AppConfigService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: BASE_PATH, useFactory: getApiBaseUrl, deps: [AppConfigService] },
    LoaderService,
    AuthGuard,
    DatePipe,
    LoginService,
    MonitoringService,
    BaseDataService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
