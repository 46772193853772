import { Injectable } from '@angular/core';
import { CollatePaginateQueryDto } from '@shared/models';
import { Observable } from 'rxjs';
import {
  GeneralCorrespondenceService as ApiGeneralCorrespondenceService,
  CreateGeneralCorrespondenceDto,
  CreateGeneralCorrespondenceReplyDto,
  GeneralCorrespondenceDto,
  GeneralCorrespondenceDtoPaginatedList,
  GeneralCorrespondenceReplyDto,
  GeneralCorrespondenceReplyDtoPaginatedList,
} from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class GeneralCorrespondenceService {
  public constructor(private apiGeneralCorrespondenceService: ApiGeneralCorrespondenceService) {}

  public addGeneralCorrespondence(body: CreateGeneralCorrespondenceDto): Observable<GeneralCorrespondenceDto> {
    return this.apiGeneralCorrespondenceService.addGeneralCorrespondences(body);
  }

  public addGeneralCorrespondenceReply(body: CreateGeneralCorrespondenceReplyDto): Observable<GeneralCorrespondenceReplyDto> {
    return this.apiGeneralCorrespondenceService.addGeneralCorrespondencesReply(body);
  }

  public closeGeneralCorrespondenceById(id: string): Observable<any> {
    return this.apiGeneralCorrespondenceService.closeGeneralCorrespondence(id);
  }

  public getGeneralCorrespondenceById(id: string): Observable<GeneralCorrespondenceDto> {
    return this.apiGeneralCorrespondenceService.getGeneralCorrespondenceById(id);
  }

  public getGeneralCorrespondences(body: CollatePaginateQueryDto): Observable<GeneralCorrespondenceDtoPaginatedList> {
    return this.apiGeneralCorrespondenceService.getGeneralCorrespondences(
      body.filters,
      body.sortBy,
      body.searchBy,
      body.sortByDescending,
      body.pageSize,
      body.pageIndex,
    );
  }

  public getGeneralCorrespondencesReplies(id: string, body: CollatePaginateQueryDto): Observable<GeneralCorrespondenceReplyDtoPaginatedList> {
    return this.apiGeneralCorrespondenceService.getGeneralCorrespondencesReplies(
      id,
      body.filters,
      body.sortBy,
      body.searchBy,
      body.sortByDescending,
      body.pageSize,
      body.pageIndex,
    );
  }
}
