import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/core/app-config/app-config.service';
import { NotificationDto } from 'src/app/notifications/models/notificationDto';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  apiBaseUrl = this.config.getConfig().api.baseUrl;
  notificationsPath = '/api/notification/';

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
  ) {}

  getUserNotifications() {
    return this.http.get<NotificationDto[]>(this.apiBaseUrl + this.notificationsPath + 'user').pipe(catchError(this.handleError));
  }

  updateNotification(notification: NotificationDto) {
    return this.http.put(this.apiBaseUrl + this.notificationsPath, notification).pipe(catchError(this.handleError));
  }

  setAllUserNotificationsToRead() {
    return this.http.get(this.apiBaseUrl + this.notificationsPath + 'readall').pipe(catchError(this.handleError));
  }

  deleteNotification(id: string) {
    return this.http.delete(this.apiBaseUrl + this.notificationsPath + id).pipe(catchError(this.handleError));
  }

  deleteAllUserNotifications() {
    return this.http.delete(this.apiBaseUrl + this.notificationsPath).pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    return throwError(() => errorMessage);
  }
}
