/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CaseWorkerDto } from './caseWorkerDto';
import { FileDto } from './fileDto';
import { UserGroupDto } from './userGroupDto';

export interface GeneralCorrespondenceDto { 
    id?: string;
    subject?: string;
    body?: string;
    createdByUser?: CaseWorkerDto;
    sentByGroup?: UserGroupDto;
    receivingGroup?: UserGroupDto;
    receivingCountryCode?: string;
    createdAt?: Date;
    updatedAt?: Date;
    lastResponseAt?: Date;
    attachments?: Array<FileDto>;
    closed?: boolean;
}