import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', '../../../../assets/bootstrap-utilities.min.css'],
})
export class FooterComponent {
  public constructor() {
    // Enpty constructor
  }
}
