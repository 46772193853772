export enum PermissionTypes {
  CaseAdditionalInformationAdd = 'case-additional-information-add',
  CaseAdditionalInformationRequest = 'case-additional-information-request',
  CaseAllocate = 'case-allocate',
  CaseArchive = 'case-archive',
  CaseCancel = 'case-cancel',
  CaseClose = 'case-close',
  CaseCorrespondenceAdd = 'case-correspondence-add',
  CaseCreate = 'case-create',
  CaseDeadlineRequestCreate = 'case-deadline-request-create',
  CaseDelete = 'case-delete',
  CaseDownload = 'case-download',
  CaseOutcomeCreate = 'case-outcome-create',
  CaseOutcomeReturnSet = 'case-outcome-return-set',
  CaseProcessMinuteAdd = 'case-process-minute-add',
  CaseProcessMinuteDelete = 'case-process-minute-delete',
  CaseProcessMinuteUpdate = 'case-process-minute-update',
  CaseProcessMinuteView = 'case-process-minute-view',
  CaseShareOutcome = 'case-share-outcome',
  CaseTaskCreate = 'case-task-create',
  CaseTaskDelete = 'case-task-delete',
  CaseTaskRespond = 'case-task-respond',
  CaseTransitSetCompleted = 'case-transit-set-completed',
  CaseTranslate = 'case-translate',
  CaseTravelDocumentRequest = 'case-travel-document-request',
  CaseView = 'case-view',
  CaseViewAll = 'case-view-all',
  CaseViewReceived = 'case-view-received',
  CaseViewSent = 'case-view-sent',
  CaseViewTaskGeneral = 'case-view-task-general',
  CaseViewTaskProcessMinute = 'case-view-task-process-minute',
  CaseViewTaskRequest = 'case-view-task-request',
  CaseViewTaskTranslate = 'case-view-task-translate',
  StatisticsView = 'statistics-view',
  CaseViewTaskAllWhenAssigned = 'case-view-task-all-when-assigned',
  CaseViewTasksFromDepartments = 'case-view-tasks-from-departments',
  CaseViewAllApproved = 'case-view-all-approved',
  CaseOutcomeView = 'case-outcome-view',
  CaseCorrespondenceAddInternational = 'case-correspondence-add-international',
  CaseCorrespondenceViewInternational = 'case-correspondence-view-international',
  CaseTaskCreateGeneral = 'case-task-create-general',
  CaseTaskCreateProcessMinute = 'case-task-create-process-minute',
  CaseTaskCreateRequest = 'case-task-create-request',
  CaseTaskCreateTranslate = 'case-task-create-translate',
  MainAgency = 'main-agency',
  ReturnScheduleCalendarView = 'return-schedule-calendar-view',
  CaseTaskRespondGeneral = 'case-task-respond-general',
  CaseTaskRespondProcessMinute = 'case-task-respond-process-minute',
  CaseTaskRespondRequest = 'case-task-respond-request',
  CaseTaskRespondTranslate = 'case-task-respond-translate',
  CaseStatusDashboardView = 'case-status-dashboard-view',
  MainAgencyPrimary = 'main-agency-primary',
  CaseTaskCreateInternational = 'case-task-create-international',
  InterviewSchedule = 'interview-schedule',
  InterviewManage = 'interview-manage',
  CaseHistoryView = 'case-history-view',
  RegularUser = 'regular-user',
  WorkshopAdmin = 'workshop-admin',
  GeneralCorrespondenceView = 'general-correspondence-view',
}
