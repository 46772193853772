<div class="dialog-header">
  <h2 mat-dialog-title>{{ 'CASE_CORRESPONDENCE.NEW_REPLY' | translate }}</h2>
</div>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div class="container">
      <mat-form-field>
        <mat-label>{{ 'CASE_CORRESPONDENCE.MESSAGE' | translate }}</mat-label>
        <textarea formControlName="body" type="text" rows="3" matInput required></textarea>
      </mat-form-field>

      <app-file-upload (multipleFilesOutput)="fileOutputHandler($event)" [multiple]="true" [multipleFilesInput]="getAttachments()"> </app-file-upload>
    </div>
  </div>

  <div mat-dialog-actions class="align-center">
    <button mat-button color="primary" cdkFocusInitial type="submit"><mat-icon>send</mat-icon> {{ 'ACTIONS.SEND' | translate }}</button>
    <button mat-button mat-dialog-close color="primary"><mat-icon>cancel</mat-icon> {{ 'ACTIONS.CANCEL' | translate }}</button>
  </div>
</form>
