import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.interface';
import { Observable, concatMap, filter, first, of } from 'rxjs';
import {
  CreateGeneralCorrespondenceReplyDto,
  FileDto,
  GeneralCorrespondenceDto,
  GeneralCorrespondenceReplyDto,
  GeneralCorrespondenceReplyDtoPaginatedList,
  UserDataDto,
} from 'swagger';
import { CreateGeneralCorrespondenceReplyDialogComponent } from '../../dialogs/create-general-correspondence-reply-dialog/create-general-correspondence-reply-dialog.component';
import { File2Service, GeneralCorrespondenceService, LoginService } from '@shared/services';
import { CollatePaginateQueryDto } from '@shared/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-general-correspondence-thread-view',
  templateUrl: './general-correspondence-thread-view.component.html',
  styleUrls: ['./general-correspondence-thread-view.component.scss', '../../../../../assets/bootstrap-utilities.min.css'],
})
export class GeneralCorrespondenceThreadViewComponent implements OnInit {
  @Input()
  public generalCorrespondence: GeneralCorrespondenceDto;
  public generalCorrespondenceReplies: GeneralCorrespondenceReplyDto[] | undefined;
  protected pageSizeOptions = [5, 10, 20];
  protected collatePaginateQueryDto: CollatePaginateQueryDto = {
    pageSize: 20,
    pageIndex: 0,
    sortByDescending: true,
    sortBy: ['createdAt'],
  };

  private generalCorrespondenceReplyPaginatedList: GeneralCorrespondenceReplyDtoPaginatedList;
  private currentUser: UserDataDto;

  public constructor(
    private _dialog: MatDialog,
    private _generalCorrespondenceService: GeneralCorrespondenceService,
    private _loginService: LoginService,
    private _fileService: File2Service,
    private _translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.getGeneralCorrespondenceReplyPaginatedList$(this.generalCorrespondence.id as string).subscribe(
      (e: GeneralCorrespondenceReplyDtoPaginatedList) => {
        this.generalCorrespondenceReplyPaginatedList = e;
        this.generalCorrespondenceReplies = e.items;
      },
    );

    this.getUserId$().subscribe((e: UserDataDto | undefined) => {
      if (e) {
        this.currentUser = e;
      }
    });
  }

  protected onClose(): void {
    const message = this._translateService.instant('UI.GENERAL_CORRESPONDENCE.CONFIRM_CLOSING_MESSAGE_THREAD');

    this._dialog
      .open(ConfirmationDialogComponent, {
        data: { message: message } as ConfirmationDialogData,
      })
      .afterClosed()
      .pipe(
        first(),
        filter((e) => e === true),
        concatMap(() => this.closeGeneralCorresspondence$()),
      )
      .subscribe(() => {
        location.reload();
      });
  }

  private getUserId$(): Observable<UserDataDto | undefined> {
    return this._loginService.loggedInUser$.pipe(first());
  }

  private closeGeneralCorresspondence$(): Observable<unknown> {
    return this._generalCorrespondenceService.closeGeneralCorrespondenceById(this.generalCorrespondence.id as string).pipe(first());
  }

  private getGeneralCorrespondenceReplyPaginatedList$(id: string): Observable<GeneralCorrespondenceReplyDtoPaginatedList> {
    return this._generalCorrespondenceService.getGeneralCorrespondencesReplies(id, this.collatePaginateQueryDto).pipe(first());
  }

  protected get getTotalItems(): number {
    return this.generalCorrespondenceReplyPaginatedList?.totalItems ?? 0;
  }

  protected get getPageSize(): GeneralCorrespondenceReplyDto[] | undefined {
    return this.generalCorrespondenceReplyPaginatedList?.items;
  }

  protected selectedPage(pageEvent: PageEvent) {
    this.collatePaginateQueryDto.pageIndex = pageEvent.pageIndex;
    this.collatePaginateQueryDto.pageSize = pageEvent.pageSize;
    this.loadData();
  }

  public isReplyFromUser(reply: GeneralCorrespondenceReplyDto): boolean {
    return reply.createdByUser?.id === this.currentUser.id;
  }

  public canClose(): boolean {
    return this.generalCorrespondence.createdByUser?.id === this.currentUser.id;
  }

  public showAttachments(generalCorrespondence: GeneralCorrespondenceDto): boolean {
    return generalCorrespondence.attachments != null && generalCorrespondence.attachments.length > 0;
  }

  public refreshGeneralCorrespondence() {
    if (!this.generalCorrespondence.id) {
      return;
    }

    this._generalCorrespondenceService
      .getGeneralCorrespondenceById(this.generalCorrespondence.id)
      .pipe(first())
      .subscribe((res) => (this.generalCorrespondence = res));
  }

  public getReplyAuthor(row: GeneralCorrespondenceReplyDto): string {
    if (row.createdByUser) {
      return row.createdByUser.name ?? '';
    } else {
      return row.sentByUserGroup?.formattedName ?? '';
    }
  }

  public onReply(): void {
    this._dialog
      .open(CreateGeneralCorrespondenceReplyDialogComponent)
      .afterClosed()
      .pipe(
        first(),
        concatMap((e: CreateGeneralCorrespondenceReplyDto) => {
          if (!e) {
            return of(undefined);
          }
          return this.addGeneralCorrespondenceReply$(e);
        }),
      )
      .subscribe((e: GeneralCorrespondenceReplyDto | undefined) => {})
      .add(() => {
        this.loadData();
        this.refreshGeneralCorrespondence();
      });
  }

  protected onDownloadFile(attachment: FileDto): void {
    this._fileService.downloadFile(attachment);
  }

  private addGeneralCorrespondenceReply$(body: CreateGeneralCorrespondenceReplyDto): Observable<GeneralCorrespondenceReplyDto> {
    body.generalCorrespondenceId = this.generalCorrespondence.id!;
    return this._generalCorrespondenceService.addGeneralCorrespondenceReply(body);
  }
}
