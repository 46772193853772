import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateGeneralCorrespondenceReplyDto } from 'swagger';
import { FileDto } from 'swagger/model/fileDto';

@Component({
  selector: 'app-create-general-correspondence-reply-dialog',
  templateUrl: './create-general-correspondence-reply-dialog.component.html',
  styleUrls: ['./create-general-correspondence-reply-dialog.component.scss'],
})
export class CreateGeneralCorrespondenceReplyDialogComponent implements OnInit {
  protected formGroup: FormGroup;

  public constructor(
    private dialogRef: MatDialogRef<CreateGeneralCorrespondenceReplyDialogComponent>,
    private formBuilder: FormBuilder,
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      body: [],
      attachments: [null],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    const reply: CreateGeneralCorrespondenceReplyDto = {
      body: this.formGroup.get('body')?.value,
      attachments: this.formGroup.get('attachments')?.value,
    };

    this.dialogRef.close(reply);
  }

  public fileOutputHandler(uploadedAttachments: FileDto[] | null): void {
    if (uploadedAttachments) {
      this.formGroup.patchValue({ attachments: uploadedAttachments });
    }
  }

  public getAttachments(): FileDto[] | null {
    const control = this.formGroup.get('attachments');
    if (control && control?.value !== '') {
      return control.value as FileDto[];
    }
    return null;
  }
}
