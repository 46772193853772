import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CaseStateService } from '../../../../case/state/case-state.service';
import { Observable, first, takeUntil } from 'rxjs';
import { SubscriptionCleaner } from '@shared/helper/subscription-cleaner/subscription-cleaner.component';
import { PermissionTypes } from '@shared/models/permissionTypes';
import { BaseDataDto, CountryDto, CreateGeneralCorrespondenceDto, FileDto, UserGroupDto } from 'swagger';
import { ApplicationService, UserGroupService } from '@shared/services';

@Component({
  selector: 'app-create-general-correspondence-dialog',
  templateUrl: './create-general-correspondence-dialog.component.html',
  styleUrls: ['./create-general-correspondence-dialog.component.scss', '../../../../../assets/bootstrap-utilities.min.css'],
})
export class CreateGeneralCorrespondenceDialogComponent extends SubscriptionCleaner implements OnInit {
  protected caseTranslationLanguage: string | undefined = undefined;
  protected formGroup: FormGroup;
  protected permissionTypes = PermissionTypes;
  protected countries: CountryDto[];
  protected agencies: UserGroupDto[] | undefined = undefined;
  protected departments: UserGroupDto[] | undefined = undefined;

  public constructor(
    private dialogRef: MatDialogRef<CreateGeneralCorrespondenceDialogComponent>,
    private formBuilder: FormBuilder,
    private caseStateService: CaseStateService,
    private userGroupService: UserGroupService,
    private applicationService: ApplicationService,
  ) {
    super();

    this.caseStateService
      .selectIsCaseTranslationLanguage$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: string | undefined) => {
        this.caseTranslationLanguage = e;
      });
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      subject: [],
      body: [],
      receivingCountry: [],
      receivingAgency: [],
      receivingDepartment: [null],
      attachments: [null],
    });

    this.getBaseData$()
      .pipe(first())
      .subscribe((e: BaseDataDto) => {
        this.countries = e.targetCountries;
        this.countries.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  private getBaseData$(): Observable<BaseDataDto> {
    return this.applicationService.getBaseData();
  }

  private getAgenciesByCountryCode$(countryCode: string): Observable<UserGroupDto[]> {
    return this.userGroupService.getUserGroupsByCountryCode(countryCode, true);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public fileOutputHandler(uploadedAttachments: FileDto[] | null): void {
    if (uploadedAttachments) {
      this.formGroup.patchValue({ attachments: uploadedAttachments });
    }
  }

  public onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    const userGroup: UserGroupDto = this.formGroup.get('receivingDepartment')?.value ?? this.formGroup.get('receivingAgency')?.value;
    const country: CountryDto = this.formGroup.get('receivingCountry')?.value;

    const generalCorrespondence: CreateGeneralCorrespondenceDto = {
      body: this.formGroup.get('body')?.value,
      subject: this.formGroup.get('subject')?.value,
      receivingCountryCode: country.code,
      receivingUserGroupId: userGroup?.id,
      attachments: this.formGroup.get('attachments')?.value,
    };

    this.dialogRef.close(generalCorrespondence);
  }

  protected onCountrySelection(country: CountryDto) {
    this.getAgenciesByCountryCode$(country.code)
      .pipe(first())
      .subscribe((e: UserGroupDto[]) => {
        this.agencies = e;
      });
  }

  public onAgencySelection(agency: UserGroupDto): void {
    this.departments = agency.childGroups;
  }

  public getAttachments(): FileDto[] | null {
    const control = this.formGroup.get('attachments');
    if (control && control?.value !== '') {
      return control.value as FileDto[];
    }
    return null;
  }
}
