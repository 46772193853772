import { Component, OnInit } from '@angular/core';
import { Observable, concatMap, first, of } from 'rxjs';
import { GeneralCorrespondenceDto } from 'swagger';
import { GeneralCorrespondenceStateService } from '../../general-correspondence-state.service';
import { Router } from '@angular/router';
import { GeneralCorrespondenceService } from '@shared/services';

@Component({
  selector: 'app-general-correspondence-thread',
  templateUrl: './general-correspondence-thread.component.html',
  styleUrls: ['./general-correspondence-thread.component.scss'],
})
export class GeneralCorrespondenceThreadComponent implements OnInit {
  protected generalCorrespondence: GeneralCorrespondenceDto;
  protected ready = false;

  public constructor(
    private _generalCorrespondenceStateService: GeneralCorrespondenceStateService,
    private _router: Router,
    private _generalCorrespondenceService: GeneralCorrespondenceService,
  ) {}

  public ngOnInit(): void {
    const urlSplits = this._router.routerState.snapshot.url.split('/');
    const id = urlSplits[urlSplits.length - 1];
    this._generalCorrespondenceStateService.generalCorrespondence$
      .pipe(
        first(),
        concatMap((e: GeneralCorrespondenceDto | undefined) => {
          if (!e) {
            return this.getGeneralCorrespondenceById$(id);
          } else {
            return of(e);
          }
        }),
      )
      .subscribe((e: GeneralCorrespondenceDto | undefined) => {
        if (e) {
          this.generalCorrespondence = e;
        }
      })
      .add(() => {
        this.ready = true;
      });
  }

  private getGeneralCorrespondenceById$(id: string): Observable<GeneralCorrespondenceDto> {
    if (this.generalCorrespondence) {
      return of(this.generalCorrespondence);
    }

    return this._generalCorrespondenceService.getGeneralCorrespondenceById(id).pipe(first());
  }
}
