export * from './application.service';
import { ApplicationService } from './application.service';
export * from './calendar.service';
import { CalendarService } from './calendar.service';
export * from './case.service';
import { CaseService } from './case.service';
export * from './file.service';
import { FileService } from './file.service';
export * from './generalCorrespondence.service';
import { GeneralCorrespondenceService } from './generalCorrespondence.service';
export * from './helpCenter.service';
import { HelpCenterService } from './helpCenter.service';
export * from './iCAO.service';
import { ICAOService } from './iCAO.service';
export * from './keycloak.service';
import { KeycloakService } from './keycloak.service';
export * from './login.service';
import { LoginService } from './login.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export * from './task.service';
import { TaskService } from './task.service';
export * from './translation.service';
import { TranslationService } from './translation.service';
export * from './userGroup.service';
import { UserGroupService } from './userGroup.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './workflow.service';
import { WorkflowService } from './workflow.service';
export * from './workshop.service';
import { WorkshopService } from './workshop.service';
export const APIS = [ApplicationService, CalendarService, CaseService, FileService, GeneralCorrespondenceService, HelpCenterService, ICAOService, KeycloakService, LoginService, NotificationService, StatisticsService, TaskService, TranslationService, UserGroupService, UsersService, WorkflowService, WorkshopService];
