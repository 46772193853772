import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneralCorrespondenceDto } from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class GeneralCorrespondenceStateService {
  private generalCorrespondenceSubject$: BehaviorSubject<GeneralCorrespondenceDto | undefined> = new BehaviorSubject<
    GeneralCorrespondenceDto | undefined
  >(undefined);

  public generalCorrespondence$: Observable<GeneralCorrespondenceDto | undefined> = this.generalCorrespondenceSubject$.asObservable();

  public SignalGeneralCorrespondence(data: GeneralCorrespondenceDto): void {
    this.generalCorrespondenceSubject$.next(data);
  }
}
