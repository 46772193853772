import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '@shared/services/authguard';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from '../material/material.module';
import {
  CreateGeneralCorrespondenceDialogComponent,
  CreateGeneralCorrespondenceReplyDialogComponent,
  GeneralCorrespondenceInboxComponent,
  GeneralCorrespondenceThreadViewComponent,
} from './components/index';
import { GeneralCorrespondenceComponent, GeneralCorrespondenceThreadComponent } from './pages/index';

const routes: Routes = [
  {
    path: 'general-correspondence',
    component: GeneralCorrespondenceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'general-correspondence/thread/:id',
    component: GeneralCorrespondenceThreadComponent,
    canActivate: [AuthGuard],
  },
];

const pages = [GeneralCorrespondenceComponent, GeneralCorrespondenceThreadComponent];
const components = [
  GeneralCorrespondenceInboxComponent,
  GeneralCorrespondenceThreadViewComponent,
  CreateGeneralCorrespondenceDialogComponent,
  CreateGeneralCorrespondenceReplyDialogComponent,
];

@NgModule({
  declarations: [pages, components],
  imports: [CommonModule, MaterialModule, SharedModule, RouterModule.forChild(routes)],
  providers: [AuthGuard],
})
export class GeneralCorrespondenceModule {}
