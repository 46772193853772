/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NotificationCategory } from './notificationCategory';
import { NotificationType } from './notificationType';

export interface NotificationDto { 
    userId?: string;
    type?: NotificationType;
    reference?: string;
    isRead?: boolean;
    id?: string;
    created?: Date;
    daysLeftForRemoval?: number;
    caseId?: string;
    notificationCategory?: NotificationCategory;
}