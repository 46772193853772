/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GeneralCorrespondenceDto } from './generalCorrespondenceDto';

export interface GeneralCorrespondenceDtoPaginatedList { 
    items?: Array<GeneralCorrespondenceDto>;
    pageIndex?: number;
    totalPages?: number;
    totalItems?: number;
    readonly hasPreviousPage?: boolean;
    readonly hasNextPage?: boolean;
    filterableProperties?: Array<string>;
}