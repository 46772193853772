import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  UserGroupService as ApiUserGroupService,
  CreateAgencyUserGroupDto,
  CreateDepartmentUserGroupDto,
  UpdateAgencyDto,
  UpdateDepartmentDto,
  UserGroupDto,
} from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  public constructor(private apiUserGroupService: ApiUserGroupService) {}

  public getUserGroupsByCurrentUserCountry(onlyVisible = true, onlyPublic = false): Observable<UserGroupDto[]> {
    return this.apiUserGroupService.getUserGroupsByCurrentUserCountry(onlyVisible, onlyPublic);
  }

  public getUserGroupsByCountryCode(countryCode: string, mainOnly: boolean): Observable<UserGroupDto[]> {
    return this.apiUserGroupService.getUserGroupsByCountryCode(countryCode, mainOnly);
  }

  public addAgencyUserGroup(body: CreateAgencyUserGroupDto): Observable<string> {
    if (body.parentId) {
      throw Error('agency group is not allowed to have parentId');
    }

    return this.apiUserGroupService.addAgencyUserGroup(body);
  }

  public addDepartmentUserGroup(body: CreateDepartmentUserGroupDto): Observable<string> {
    if (!body.parentId) {
      throw Error('department group needs to have parentId');
    }

    return this.apiUserGroupService.addDepartmentUserGroup(body);
  }

  public updateAgencyUserGroup(body: UpdateAgencyDto): Observable<any> {
    return this.apiUserGroupService.updateAgencyUserGroup(body);
  }

  public updateDepartmentUserGroup(body: UpdateDepartmentDto): Observable<any> {
    return this.apiUserGroupService.updateDepartmentUserGroup(body);
  }

  public deleteUserGroup(id: string): Observable<string> {
    return this.apiUserGroupService.deleteUserGroup(id);
  }
}
